import { API } from '../api';

const getLinks = (groupId, agendaId) => {
    return API.get('client/agenda/agenda/get-links', { groupId: groupId, agendaId: agendaId })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data
            }

            return {};
        })
}

const buildWeekScheduleAgenda = (start, view = 'week', agendaId = null, category = null) => {
    let data = { startDate: start, view: view }
    if (agendaId) {
        data['agenda'] = agendaId;
    }

    if (category) {
        data['categoryId'] = category;
    }

    return API.get('client/schedule/schedule/build-week-schedule', data)
        .then(
            response => {
                if (!API.isSuccess(response)) {
                    return false;
                }

                return response.data;
            });

};

const buildWeekScheduleGroup = (start, view = 'day', groupId, agendas = null) => {
    return API.get('client/schedule/schedule/build-week-schedule-group', { startDate: start, view: view, groupId: groupId, agendas: agendas })
        .then(
            response => {
                if (!API.isSuccess(response)) {
                    return false;
                }

                return response.data;
            });

};

const buildSchedule = (start, view = 'day', groupId, agendas = null) => {
    let data = { startDate: start, view: view, groupId: groupId, agendas: agendas }

    return API.get('client/schedule/schedule/build-schedule', data)
        .then(
            response => {                
                if (!API.isSuccess(response)) {
                    return false;
                }

                return response.data;
            });

};

const buildWeekScheduleAgendaHomeVisits = (groupId, date, agenda) => {
    return API.get('client/schedule/schedule/build-week-schedule-home-visits', { date: date, groupId: groupId, for: agenda })
        .then(
            response => {
                if (API.isSuccess(response)) {
                    return response.data;
                }

                throw response.data;
            });
}

const doRefreshPage = (agendaId) => {
    return API.get('client/agenda/agenda/refresh', { agendaId: agendaId })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data;
            }

            return false;
        });
};

export const ApiAgendaService = {
    getLinks,
    buildWeekScheduleAgenda,
    buildWeekScheduleGroup,
    buildSchedule,
    buildWeekScheduleAgendaHomeVisits,
    doRefreshPage
};