import { API } from '../api';

const getNotifications = (groupId) => {
    return API.get('client/client/client/get-notifications', { groupId: groupId })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.notifications;
            }

            return {};
        })
};

const logout = () => {
    return API.post('client/client/client/logout');
};

const updateProfilePhoto = (formData) => {
    API.post('client/client/client/update-images', { data: formData }, { 'Content-Type': 'multipart/form-data' })
}

const updateSetting = (clientId, key, value) => {
    return API.post('client/client/client/update-setting', { data: { clientId: clientId, key: key, value: value } })
        .then(response => {
            if (API.isSuccess(response)) {
                return true;
            }

            return false;
        });
}

const getAgendaLinks = (groupId, clientId) => {
    return API.get('client/client/client/get-links', { groupId: groupId, clientId: clientId })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data
            }

            return {};
        })
}

const enable2fa = (client, code = null) => {
    return API.get('client/client/client/enable-2fa', { client: client, code: code })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data;
            }

            throw response.data[0] ? response.data[0] : null;
        });
};

const disable2fa = (client, code = null) => {
    return API.post('client/client/client/disable-2fa', { data: { client: client, code: code } })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data;
            }

            throw response.data[0] ? response.data[0] : null;
        });
};

export const ApiClientService = {
    getAgendaLinks,
    getNotifications,
    logout,
    updateProfilePhoto,
    updateSetting,
    enable2fa,
    disable2fa
};