import { reverse } from 'named-urls';
import routes from '../routing/routes';
import { API } from '../services/api';
import { ApiClientService } from '../services/api/index';
import { UtilClient, UtilSystem, UtilUrl } from './';

const handleAutoLogin = async (props) => {
    return await API.get(process.env.REACT_APP_API_HOST.trim() + '/' + props.match.params.path)
        .then(response => {
            if (!API.isSuccess(response)) {
                throw new Error('not-authenticated');
            }

            if (response.data.type === 'login') {
                return handleLogin(response.data, props);
            } else {
                throw new Error('not-authenticated');
            }
        })
        .catch(() => props.history.push(UtilUrl.generate(routes.auth.login)));
}

const handleLogin = (data, props) => {
    let { access_key, agenda, client, group, schemaData, redirect } = data;

    if (redirect === 'two-factor') {
        props.actions.clientLoggedIn({
            logged_in: client,
            active: null,
            access_key: null
        });

        return 'two-factor';
    } else {
        props.actions.notificationsLoaded(client.notifications);
        client.notifications = undefined;

        props.actions.agendaSet('active', agenda);
        props.actions.groupUpdate(group);
        props.actions.clientLoggedIn({
            logged_in: client,
            active: client,
            access_key: access_key
        });
        props.actions.changeSetting('sessionId', UtilSystem.generateRandomId());
        props.actions.changeSetting('activeModule', 'agenda');

        props.actions.schemaDataLoaded(schemaData);
        props.actions.changeSetting('modus', 'view');
        props.actions.changeSetting('modusParams', {});

        //let { viewAfterLogin } = client.settings;
        let viewAfterLogin = null;

        if (viewAfterLogin) {
            viewAfterLogin = viewAfterLogin.split('_');

            let groupId = viewAfterLogin[1];
            if (groupId) {
                client.groups.forEach(g => {
                    if (parseInt(g.id) === parseInt(groupId)) {
                        group = g;
                    }
                });
            }
        }

        let viewAfterLoginLink = null; // UtilUrl.buildViewAfterLogin(client.settings);

        if (viewAfterLoginLink) {
            props.history.push(viewAfterLoginLink);
        } else {
            let { modules } = group;
            
            if (modules) {
                if ('agenda' in modules && modules.agenda.active) {
                    props.history.push(reverse(routes.agenda.myagenda, { agenda: agenda.id }));
                    props.actions.changeSetting('activeModule', 'agenda');
                } else if ('reservation' in modules && modules.reservation.active) {
                    props.history.push(reverse(routes.reservation.reservations.overview));
                    props.actions.changeSetting('activeModule', 'reservation');
                }
            } else {
                props.history.push(reverse(routes.dashboard));
            }
        }
    }
}

const logout = (props) => {
    if (UtilClient.isLoggedIn()) {
        ApiClientService.logout();
    }

    props.actions.clientLoggedOut();
    props.actions.groupRemove();
    props.actions.schemaDataUnloaded();
    props.actions.changeSetting('sessionId', null);
    props.actions.changeSetting('lastActivity', null);
    props.actions.changeSetting('activeModule', null);
    props.actions.changeSetting('caller', {
        id: null,
        results: []
    });
    props.actions.setContact(null);
}

export const UtilAuthentication = {
    logout,
    handleLogin,
    handleAutoLogin,
};