import { t } from 'i18next';

const assembleAddress = (item) => {
    let address = item.street + ' ' + item.number;
    if (item.box) {
        address += ' ' + t('contacts.contact:modal:box').toLowerCase() + ' ' + item.box;
    }

    let postalPlace = item.postalCode + ' ' + item.city;
    let country = item.country;

    return (address + (postalPlace ? ', ' + postalPlace : '') + (country ? ', ' + country : '')).trim();
}

export const UtilContact = {
    assembleAddress
};