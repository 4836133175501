import React from 'react';
import { Button } from 'reactstrap';

const ButtonLabel = props => (
    <Button color={props.color ? props.color : 'default'} type={props.type ? props.type : 'button'}
        onClick={props.onClick}
        className={'btn-labeled ' + props.className}
        disabled={props.disabled ? true : false}
        size={props.size ? props.size : 'md'}
    >
        <span className="btn-label">
            <i className={'fa fa-' + props.icon} aria-hidden></i>
        </span>
        {props.children}
    </Button>
)
export default ButtonLabel;