import { reverse } from "named-urls";
import queryString from 'query-string';
import routes from "../routing/routes";

const generate = (route, params = {}) => {
    return reverse(route, params);
};

/**
 * Online docs: https://www.npmjs.com/package/query-string
 */
const parseQueryString = (query) => {
    if (!query) {
        return {};
    }

    let parsed = queryString.parse(query, { decode: true, parseNumbers: true });
    return parsed;
}

const buildViewAfterLogin = (settings, activeGroup) => {
    let { viewAfterLogin } = settings;

    if (!viewAfterLogin) return null;

    viewAfterLogin = viewAfterLogin.split('_');

    let groupId = parseInt(viewAfterLogin[1]);
    if (groupId && activeGroup && groupId !== activeGroup.id) return null;

    let redirect = null;
    switch (viewAfterLogin[0]) {
        case 'groupoverview':
            redirect = reverse(routes.agenda.group);
            break;
        case 'agenda':
            redirect = reverse(routes.agenda.myagenda, { agenda: viewAfterLogin[2] });
            break;
        case 'subgroup':
            redirect = reverse(routes.agenda.subgroup, { subgroup: viewAfterLogin[2] });
            break;
        case 'homevisits':
            redirect = reverse(routes.homevisits.overview);
            break;
        case 'searchfreeslot':
            redirect = reverse(routes.agenda.searchfreeslot);
            break;
        case 'myagenda':
        default:
            redirect = reverse(routes.agenda.myagenda);
            break;
    }

    return redirect;
}

export const UtilUrl = {
    generate,
    parseQueryString,
    buildViewAfterLogin
};