import { API } from '../../services/api';
import { AGENDA_SET } from './agenda.actions';
import { UtilAgenda } from '../../utils';

/*
   Hook into setting changes in order to change layout.
*/
const agenda = store => next => action => {
    let result = next(action)

    if (action.type === AGENDA_SET) {
        API.setAgenda(action.value.id);
        UtilAgenda.setAgenda(action.value);
    }

    return result
}

export default agenda;