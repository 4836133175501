import React from 'react';
import { t } from 'i18next';
import { ButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import { UtilUrl } from '../../utils/url.util';
import routes from '../../routing/routes';

class AgendaSidebar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ddOpen: false
        };
    }

    changeAgenda = (item) => {
        const { location } = this.props;
        this.props.actions.agendaSet('active', item);

        //reload
        if (location.pathname.indexOf('/agenda') < 0) {
            this.props.history.push(this.props.location.pathname);
        } else {
            let params = { agenda: item.id };
            let date = location.pathname.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/g);
            if (date) { params['date'] = date[0]; }

            this.props.history.push(UtilUrl.generate(routes.agenda.myagenda, params));
        }
    }

    toggle = () => this.setState({
        ddOpen: !this.state.ddOpen
    });

    render() {
        const { agendas } = this.props.group;

        if (agendas === undefined) return null;

        const activeAgenda = this.props.agenda;
        const btnColor = this.props.group ? 'brand' : 'danger';

        return (
            <ButtonDropdown color={btnColor} isOpen={this.state.ddOpen} toggle={this.toggle} className="btn-block">
                <DropdownToggle color={btnColor} size="sm" caret>
                    {activeAgenda ? activeAgenda.title : t('common:choose.agenda')}
                </DropdownToggle>
                <DropdownMenu>
                    {
                        agendas.map((item, i) => {
                            return (
                                <DropdownItem key={i} onClick={() => this.changeAgenda(item)} active={item.id !== activeAgenda.id ? false : true}>
                                    {item.title}
                                </DropdownItem>
                            )
                        })
                    }
                </DropdownMenu>
            </ButtonDropdown>
        );
    }
}

const mapStateToProps = state => ({ agenda: state.agenda ? state.agenda.active : null, group: state.group })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(mapStateToProps, mapDispatchToProps)(AgendaSidebar);