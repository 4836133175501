import configureStore from '../store/store';

let loggedInCLient = null;
const setClient = (client) => {
    loggedInCLient = client;
};

const isLoggedIn = () => {
    return !!loggedInCLient;
};

const hasRestrictedAccess = (client) => {
    if (!client) {
        return false;
    }

    const { agendasAllowed } = client;
    if (!agendasAllowed || agendasAllowed.length <= 0) {
        return false; // No restrictions
    }
    return true;
}

const hasAccessToAgenda = (client, agendaId) => {
    if (!client) {
        return true;
    }

    const { agendasAllowed } = client;
    if (!agendasAllowed || agendasAllowed.length <= 0) {
        return true; // No restrictions
    }

    if (agendasAllowed.includes(parseInt(agendaId))) {
        return true;
    }

    return false;
}

const hasAccessToSettings = (client) => {
    if (!client) {
        let state = configureStore().getState();
        if (state && state.client && state.client.logged_in) {
            client = state.client.logged_in;
        }
    }

    if (!client) {
        return true;
    }

    const { canOnlyViewAgenda } = client;
    if (!canOnlyViewAgenda) {
        return true; // No restrictions
    }

    return false;
}

export const UtilClient = {
    isLoggedIn,
    setClient,
    hasRestrictedAccess,
    hasAccessToAgenda,
    hasAccessToSettings,
};