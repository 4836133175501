import { API } from '../api';

const getById = (id) => {
    return API.get('client/resource/schedule/get', { id: id })
        .then((response) => {
            if (API.isSuccess(response)) {
                return response.data;
            }

            throw new Error('error');
        });
};

const getByResource = (id = null) => {
    return API.get('client/resource/schedule/get-by-resource', { resourceId: id })
        .then((response) => {
            if (API.isSuccess(response)) {
                return response.data;
            }

            return [];
        });
};

const buildSchedule = (start, view = 'day', groupId, resources = null) => {
    let data = { startDate: start, view: view, groupId: groupId, resources: resources }

    return API.get('client/resource/schedule/build-schedule', data)
        .then(
            response => {
                if (!API.isSuccess(response)) {
                    return false;
                }

                return response.data;
            });

};

const loadSchemaData = () => {
    return API.get('client/resource/schedule/load-schema-data')
        .then((response) => {
            if (API.isSuccess(response)) {
                return response.data;
            }

            return [];
        });
};

const getFreeSlotCount = (start, end, id, type) => {
    return API.get('client/resource/schedule/get-free-slot-count', { start: start, end: end, id: id, type: type })
        .then((response) => {
            if (API.isSuccess(response)) {
                return response.data;
            }
        })
        .catch((error) => { });
};

const saveBlocks = (blocks, clientId = null) => {
    return API.post('client/schedule/block/set-blocks', { data: { blocks: blocks, client: clientId } })
        .then(
            response => {
                if (API.isSuccess(response)) {
                    return true;
                }

                throw new Error(response.data);
            }
        );
};

const composeSlots = (scheduleId, data) => {
    return API.post('client/resource/schedule/slot/compose', { schedule: scheduleId, data: data })
        .then((response) => {
            if (API.isSuccess(response)) {
                return true;
            }

            return false;
        });
};

const updateSlotCategory = (slotId, categoryId, start, end) => {
    return API.post('client/resource/schedule/slot/update-category', {
        data: {
            category: categoryId,
            slotId: slotId,
            start: start,
            end: end
        }
    })
        .then((response) => {
            return response;
        });
}

const getPeriodsBySchedule = (scheduleId) => {
    return API.get('client/schedule/period/get-by-schedule', { id: scheduleId })
        .then((response) => {
            if (API.isSuccess(response)) {
                return response.data.periods;
            }

            return [];
        });
};

const updatePriorityBreakHours = (scheduleId, data) => {
    return API.post('client/resource/schedule/slot/update-priority-breakhours', { schedule: scheduleId, data: { slots: data } })
        .then((response) => {
            if (API.isSuccess(response)) {
                return true;
            }

            throw new Error('error');
        });
};

const findFirstAvailableSlotForAppointment = (appointmentId) => {
    return API.get('client/resource/schedule/find-first-available-slot', { id: appointmentId })
        .then((response) => {
            if (API.isSuccess(response)) {
                return response.data;
            }

            return [];
        });
}

const clone = (scheduleId) => {
    return API.get('client/resource/schedule/duplicate-schedule', { id: scheduleId })
        .then((response) => {
            if (API.isSuccess(response)) {
                return true;
            }

            return false;
        });
}

export const ApiResourceScheduleService = {
    getById,
    getByResource,
    buildSchedule,
    loadSchemaData,
    getFreeSlotCount,
    saveBlocks,
    composeSlots,
    getPeriodsBySchedule,
    updatePriorityBreakHours,
    updateSlotCategory,
    findFirstAvailableSlotForAppointment,
    clone
};