import { SET_CONTACT } from './contact.actions';

const contactReducer = (state = null, action) => {
    switch (action.type) {
        case SET_CONTACT:
            return action.value;
        default:
            return state;
    }
}

export default contactReducer;