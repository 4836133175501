import { API } from '../../services/api';
import { UtilClient } from '../../utils/client.util';
import { CLIENT_LOGGED_IN, CLIENT_LOGGED_OUT, CLIENT_SET, CLIENT_UPDATE } from './client.actions';
import i18n from '../../i18n';

/*
   Hook into setting changes in order to change layout.
*/
const client = store => next => action => {
    let result = next(action)

    if (action.type === CLIENT_LOGGED_IN) {
        API.setToken(action.value.access_key);
        UtilClient.setClient(action.value.logged_in);
    } else if (action.type === CLIENT_LOGGED_OUT) {
        API.setToken(null);
        UtilClient.setClient(null);
    }

    if (action.name === 'logged_in' && (action.type === CLIENT_SET || action.type === CLIENT_UPDATE)) {
        i18n.changeLanguage(action.value.nativeLanguage);
    }

    return result
}

export default client;