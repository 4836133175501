import React from 'react';
import { Button } from 'reactstrap';
import { t } from 'i18next';
import UncontrolledTooltip from '../reactstrap/UncontrolledTooltip';
import { Link } from 'react-router-dom';

const ButtonActionEdit = props => (
    <React.Fragment>
        <Button color="info" size="sm" id={'buttonEdit' + props.id} tag={Link} to={props.to} onClick={props.onClick}>
            <i className="fas fa-pencil-alt" aria-hidden></i>
        </Button>
        <UncontrolledTooltip placement="top" target={'buttonEdit' + props.id}>
            {t('common:action-edit')}
        </UncontrolledTooltip>
    </React.Fragment>
)

export default ButtonActionEdit;