import React from 'react';
import { Button } from 'reactstrap';
import UncontrolledTooltip from '../reactstrap/UncontrolledTooltip';
import { Link } from 'react-router-dom';

const ButtonActionLink = props => (
    <React.Fragment>
        <Button color={props.color} size={props.size} id={'buttonLink' + props.id} tag={Link} to={props.to}>
            <i className={'fas fa-' + props.icon} aria-hidden></i>
        </Button>
        {props.tooltip && (
            <UncontrolledTooltip placement="top" target={'buttonLink' + props.id}>
                {props.tooltip}
            </UncontrolledTooltip>
        )}
    </React.Fragment>
);

ButtonActionLink.defaultProps = {
    color: 'info',
    size: 'sm',
}

export default ButtonActionLink;