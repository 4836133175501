import { CLIENT_SET, CLIENT_UPDATE, CLIENT_UPDATE_SETTINGS, CLIENT_REMOVE, CLIENT_ACCESS_KEY_SET, CLIENT_ACCESS_KEY_REMOVE, CLIENT_LOGGED_IN, CLIENT_LOGGED_OUT } from './client.actions';

const clientReducer = (state = null, action) => {
    switch (action.type) {
        case CLIENT_LOGGED_IN:
            return action.value;
        case CLIENT_LOGGED_OUT:
            return null;
        case CLIENT_SET:
        case CLIENT_UPDATE:
            return {
                ...state,
                [action.name]: action.value
            }
        case CLIENT_REMOVE:
            return {
                ...state,
                [action.name]: undefined
            }
        case CLIENT_UPDATE_SETTINGS:
            return {
                ...state,
                [action.name]: {
                    ...state[action.name],
                    settings: action.value
                }
            }
        case CLIENT_ACCESS_KEY_SET:
            return {
                ...state,
                access_key: action.value
            }
        case CLIENT_ACCESS_KEY_REMOVE:
            return {
                ...state,
                access_key: undefined
            }
        default:
            return state;
    }
}

export default clientReducer;