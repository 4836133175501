import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import NoAccess from '../containers/Pages/NoAccess';

class PermissionChecker extends React.Component {

    render() {
        if (!this.clientHasccess()) {
            return <NoAccess />
        }

        return this.props.children;
    }

    clientHasccess = () => {
        /*const { client, location } = this.props;
        
        if (!client) {
            return true;
        }

        let permissionMapping = {
            '/settings/holidays': 'ROLE_SETTING_HOLIDAY'
        };*/

        return true;
    }
}

const mapStateToProps = state => ({ client: state.client ? state.client.logged_in : null })

export default connect(mapStateToProps)(withRouter(PermissionChecker));