const chunk = (arr, size) => {
    const numberOfChunks = Math.ceil(arr.length / size)

    return [...Array(numberOfChunks)]
        .map((value, index) => {
            return arr.slice(index * size, (index + 1) * size)
        })
}

// Sort object by keys
const ksort = (object) => {
    // https://www.codegrepper.com/code-examples/typescript/sort+javascript+object+by+key
    return Object.keys(object).sort().reduce((obj, key) => {
        obj[key] = object[key];
        return obj;
    }, {});
};

// Sort object by keys
const sort = (object) => {
    return Object.entries(object)
        .sort(([, a], [, b]) => a > b ? 1 : -1)
        .reduce((r, [k, v]) => ({ ...r, [' ' + k]: v }), {});
};

export const UtilArray = {
    chunk,
    ksort,
    sort
};