import React from 'react';
import { t } from 'i18next';
import { ButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import routes from '../../routing/routes';
import { reverse } from 'named-urls';
import { ApiScheduleService } from '../../services/api/schedule.service';

class GroupSidebar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ddOpen: false
        };
    }

    changeGroup = (item) => {
        this.props.actions.groupUpdate(item);
        this.props.actions.setContact(null);

        // Set group location
        let selectedLocation = null;
        if (item.locations && item.locations.length === 1) {
            selectedLocation = item.locations[0];
        }
        this.props.actions.setLocation(selectedLocation);

        if (item.modules) {
            if ('agenda' in item.modules && item.modules.agenda.active) {
                let agenda = item.agendas ? item.agendas[0] : null;
                this.props.actions.agendaSet('active', agenda);

                if (agenda) {
                    ApiScheduleService.loadSchemaData()
                        .then(result => this.props.actions.schemaDataLoaded(result))
                        .then(() => {
                            this.props.history.push(reverse(routes.agenda.myagenda, { agenda: agenda.id }));
                            this.props.actions.changeSetting('activeModule', 'agenda');
                        })
                } else {
                    this.props.history.push(reverse(routes.dashboard));
                }
            } else if ('reservation' in item.modules && item.modules.reservation.active) {
                this.props.history.push(reverse(routes.reservation.reservations.overview));
                this.props.actions.changeSetting('activeModule', 'reservation');
            }
        } else {
            this.props.history.push(reverse(routes.dashboard));
        }

        //reload
        /*let location = this.props.location.pathname;

        if (location.startsWith(reverse(routes.agenda.myagenda))) {
            if (agenda) {
                this.props.history.push(reverse(routes.agenda.group));
            } else if (this.props.agenda) {
                this.props.history.push(reverse(routes.agenda.myagenda, { agenda: this.props.agenda.id }));
            } else {
                this.props.history.push(reverse(routes.agenda.myagenda));
            }

        } else {
            this.props.history.push(this.props.location.pathname);
        }*/
    }

    toggle = () => this.setState({ ddOpen: !this.state.ddOpen })

    render() {
        const { groups } = this.props.client;

        if (!groups) return null;

        const btnColor = this.props.group ? 'default' : 'danger';

        if (groups.length === 1) {
            return (
                <ul className="sidebar-nav">
                    <li className="nav-heading">
                        <span>{this.props.group.name}</span>
                    </li>
                </ul>
            )
        } else {
            return (
                <ButtonDropdown color={btnColor} isOpen={this.state.ddOpen} toggle={this.toggle} className="btn-block">
                    <DropdownToggle color={btnColor} size="sm" caret>
                        {this.props.group ? this.props.group.name : t('common:choose.group')}
                    </DropdownToggle>
                    <DropdownMenu>
                        {groups.map((item, i) => {
                            return (
                                <DropdownItem key={i} onClick={() => this.changeGroup(item)} active={!this.props.group || item.id !== this.props.group.id ? false : true}>
                                    {item.name}
                                </DropdownItem>
                            )
                        })}
                    </DropdownMenu>
                </ButtonDropdown>
            );
        }
    }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(null, mapDispatchToProps)(GroupSidebar);