import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { t } from 'i18next';

const ButtonAdd = props => (
    <Button size={props.size ? props.size : 'md'} color="success" tag={props.tag ? props.tag : Link} to={props.to} className={'btn-labeled ' + props.className}>
        <span className="btn-label"><i className={'fa fa-' + (props.icon ? props.icon : 'plus')} aria-hidden></i></span>
        {props.children ? props.children : t('common:Add')}
    </Button>
)

export default ButtonAdd;
