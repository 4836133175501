import moment from 'moment';

const create = (input = null, format = null) => {
    if (!input) {
        return moment();
    }

    if (format) {
        return moment(input, format);
    }

    return moment(input);
};

const format = (input, format = 'YYYY-MM-DD') => {
    if (!(input instanceof moment)) {
        input = moment(input);
    }

    return input.format(format);
};

const isWeekend  = (date) => {
    if ([6, 0].includes(date.day())) {
        return true;
    }
    
    return false;
}

export const UtilDate = {
    create,
    format,
    isWeekend
};