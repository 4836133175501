import { withRouter } from 'react-router';
import axios from 'axios';
import i18next from 'i18next';
import routes from '../routing/routes';
import configureStore from '../store/store';
import { UtilApi } from '../utils/api.util';
import { CLIENT_LOGGED_OUT } from '../store/actions';

let _selectedAgenda = null;
const setAgenda = (agenda) => _selectedAgenda = agenda;

let _selectedGroup = null;
const setGroup = (group) => _selectedGroup = group;

let _sessionId = null;
const setSessionId = (id) => _sessionId = id;

let _authToken = null;
const setToken = (token) => _authToken = token;

const state = configureStore().getState();

const client = axios.create({
    baseURL: process.env.REACT_APP_API_HOST.trim() + '/' + process.env.REACT_APP_API_VERSION + '/',
    headers: {
        'Content-Type': 'application/json'
    }
});

const get = (url, params) => {
    return request({
        url: url,
        method: 'GET',
        params: params,
        headers: {}
    });
};

const remove = (url, params) => {
    return request({
        url: url,
        method: 'DELETE',
        params: params,
        headers: {}
    });
};

const post = (url, data, headers = {}) => {
    return request({
        url: url,
        method: 'POST',
        data: data,
        headers: headers
    });
};

const request = (requestOptions) => {
    // Cancel concurrent requests
    UtilApi.cancelRequest(requestOptions);

    // Add new request
    let nonCancelUrls = ['client/client/client/refresh-agenda', 'client/shop/product/quick-update'];
    if (nonCancelUrls.indexOf(requestOptions.url) < 0) {
        UtilApi.addCancelToken(requestOptions)
    }

    client.defaults.headers.common['Authorization'] = _authToken ? _authToken : (state.client ? state.client.access_key : null);
    client.defaults.headers.common['X-Agenda'] = _selectedAgenda ? _selectedAgenda : (state.agenda && state.agenda.active ? state.agenda.active.id : null);
    client.defaults.headers.common['X-Group'] = _selectedGroup ? _selectedGroup : (state.group ? state.group.id : null);
    client.defaults.headers.common['X-Session'] = _sessionId ? _sessionId : (state.settings.sessionId ? state.settings.sessionId : null);

    if (requestOptions['headers']['Content-Type']) {
        client.defaults.headers.common['Content-Type'] = requestOptions['headers']['Content-Type'];
    }

    if (requestOptions['data']) {
        requestOptions['data']['lng'] = i18next.language;
    }

    if (requestOptions['params']) {
        requestOptions['params']['lng'] = i18next.language;
    }

    client.defaults.cancelToken = UtilApi.getCancelToken(requestOptions);

    return client(requestOptions)
        .then(response => {
            // console.log(response.status, response.statusText, ':', response.data.status, response.data.data);
            return response.data;
        }).catch((error) => {
            if (axios.isCancel(error)) {
                // console.log('Request aborted: ' + error);
                return false;
            } else {
                if (error.response) {
                    const { status } = error.response;

                    // console.log(status);
                    if (status === 401) {
                        UtilApi.removeAllCancelToken();
                        localStorage.removeItem('reservi-app-settings');
                        configureStore().dispatch({ type: CLIENT_LOGGED_OUT });
                        setToken(null);
                        window.location = routes.auth.login;
                    } else if (status === 500) {
                        // LocalStorageService.store('FlashMessage', {type: 'error', data: 'An error occured'});
                        // window.location = routes.error;
                    }
                }
            }
        }).then((response) => {
            UtilApi.removeCancelToken(requestOptions);
            return response;
        });
};

const isSuccess = (response) => {
    return response && response.status && response.status === 'success';
}

export const API = withRouter({ get, post, remove, isSuccess, setToken, setAgenda, setGroup, setSessionId });