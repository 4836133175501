import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import { reverse } from 'named-urls';
import { connect } from 'react-redux';
import routes from '../../routing/routes';
import GroupSidebar from '../sidebar/GroupSidebar';
import AgendaSidebar from '../sidebar/AgendaSidebar';
import LocationSidebar from '../sidebar/LocationSidebar';

class SidebarUserBlock extends Component {
    render() {
        let { client } = this.props;

        if (!client) return null;

        return (
            <Collapse id="user-block" isOpen={true}>
                <div>
                    <div className="item user-block">
                        {/* User picture */}
                        <div className="user-block-picture">
                            <div className="user-block-status">
                                <img className="img-thumbnail" src={client.image_small ? client.image_small : 'images/dummy.png'} alt="Avatar" width="60" height="60" />
                            </div>
                        </div>
                        {/* Name and Job */}
                        <div className="user-block-info">
                            <Link to={{ pathname: reverse(routes.settings.account.profile) }} className="user-block-name">
                                {client.fullName}
                            </Link>
                            <GroupSidebar {...this.props} />
                            <LocationSidebar {...this.props} />
                            {this.props.activeModule === 'agenda' && <AgendaSidebar {...this.props} />}
                        </div>
                    </div>
                </div>
            </Collapse>
        )
    }
}

const mapStateToProps = state => ({
    activeModule: state.settings.activeModule,
    showUserBlock: state.settings.showUserBlock,
    agenda: state.agenda ? state.agenda.active : null,
    client: state.client ? state.client.logged_in : null,
    group: state.group,
    selectedLocation: state.location ? state.location : null
});
export default connect(mapStateToProps)(SidebarUserBlock);