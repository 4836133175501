import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {t} from 'i18next';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../store/actions';
import ToggleFullscreen from '../ToggleFullscreen';
import HeaderRun from './Header.run'
import routes from '../../routing/routes';
import {reverse} from 'named-urls';
import {ApiClientService} from '../../services/api/client.service';
import {toast} from 'react-toastify';
import ModalSupport from '../modals/ModalSupport';
import {UtilClient} from '../../utils/client.util';
import UncontrolledTooltip from '../reactstrap/UncontrolledTooltip';
import {Button} from 'reactstrap';

class Header extends Component {
    constructor(props) {
        super(props);

        this.modalSupport = React.createRef();

        this.state = {
            showSettingLinks: this.doShowSettingsLink()
        }
    }

    doShowSettingsLink = (activeModule) => {
        if (activeModule === undefined) activeModule = this.props.settings.activeModule;
        return UtilClient.hasAccessToSettings() && activeModule === 'agenda'
    };

    componentDidMount() {
        HeaderRun();

        //this.closeOffsidebarOnClick();
        setInterval(this.watchNotificationChanges, 60 * 5 * 1000);
    }

    componentWillReceiveProps(newProps) {
        this.setState({showSettingLinks: this.doShowSettingsLink(newProps.settings.activeModule)});
    }

    toggleUserblock = e => {
        e.preventDefault();
        this.props.actions.toggleSetting('showUserBlock');
    }

    toggleOffsidebar = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('offsidebarOpen');
    }

    toggleCollapsed = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('isCollapsedText');
        this.props.actions.changeSetting('wasCollapsedBeforeSettings', false);
        this.resize()
    }

    toggleAside = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('asideToggled');
    }

    resize() {
        // all IE friendly dispatchEvent
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
        // modern dispatchEvent way
        // window.dispatchEvent(new Event('resize'));
    }

    onSearchSubmit = (e) => {
        e.preventDefault();

        const inputEl = document.getElementById('navbar-form-search-field');
        let value = inputEl.value;

        inputEl.value = '';

        this.props.history.push(reverse(routes.appointments.search, {searchStr: encodeURIComponent(value)}));
    }

    watchNotificationChanges = () => {
        const {notifications, group} = this.props;

        ApiClientService.getNotifications(group ? group.id : null)
            .then(result => {
                this.props.actions.notificationsLoaded(result);

                Object.entries(result).forEach(([key, value]) => {
                    if (!notifications || notifications[key] === undefined) {
                        return;
                    }

                    if (value <= notifications[key]) {
                        return;
                    }

                    let message = null;
                    let diff = value - notifications[key];
                    switch (key) {
                        case 'pendingTasks':
                            message = t('tools.task:pendingTasksNew', {count: diff});
                            break;
                        case 'reminders':
                            message = t('tools.reminder:remindersNew', {count: diff});
                            break;
                        case 'unreadMessages':
                            message = t('profile.mailbox:newMessage', {count: diff});
                            break;
                        default:
                            message = null;
                            break;
                    }

                    if (!message) {
                        return;
                    }

                    toast(message, {type: 'info', position: 'bottom-right'});
                });
            })
    }

    closeOffsidebarOnClick = () => {
        document.addEventListener('click', (e) => {
            if (e.path) {
                const pathIds = e.path.map((pathEl) => {
                    return pathEl.id;
                });

                if (pathIds.includes('nav-item-offsidebar-settings') || pathIds.includes('offsidebar-settings')) {
                    return;
                }

                this.props.actions.changeSetting('offsidebarOpen', false);
            }
        });
    }

    showSupportModal = (e) => {
        e.preventDefault();

        if (this.modalSupport) {
            this.modalSupport.onToggle();
        }
    }

    render() {
        const {activeModule} = this.props.settings;
        let {group} = this.props;

        if (!group) return null;

        let unreadMessages = this.props.notifications ? this.props.notifications.unreadMessages : 0;
        let pendingTasks = this.props.notifications ? this.props.notifications.pendingTasks : 0;
        let reminders = this.props.notifications ? this.props.notifications.reminders : 0;

        const badgeCount = unreadMessages + pendingTasks + reminders;

        let {modules} = group;
        modules = Object.keys(modules).filter(module => !!modules[module].active);

        return (
            <header className="topnavbar-wrapper">
                {/* START Top Navbar */}
                <nav className="navbar topnavbar">
                    {/* START navbar header */}
                    <div className="navbar-header">
                        <a className="navbar-brand" href="#/">
                            <div className="brand-logo">
                                <img className="img-fluid" src="images/reservi-logo.svg" alt="Reservi logo"/>
                            </div>
                            <div className="brand-logo-collapsed">
                                <img className="img-fluid" src="images/reservi-logo-collapsed.svg" alt="Reservi logo"/>
                            </div>
                        </a>
                    </div>
                    {/* END navbar header */}

                    {/* START Left navbar */}
                    <ul className="navbar-nav mr-auto flex-row">
                        <li className="nav-item">
                            {/* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
                            <a href="" className="nav-link d-none d-md-block d-lg-block d-xl-block"
                               onClick={this.toggleCollapsed}>
                                <em className="fas fa-bars"></em>
                            </a>
                            {/* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
                            <a href="" className="nav-link sidebar-toggle d-md-none" onClick={this.toggleAside}>
                                <em className="fas fa-bars"></em>
                            </a>
                        </li>

                        {/* Fullscreen (only desktops) */}
                        <li className="nav-item d-none d-md-block">
                            <ToggleFullscreen className="nav-link"/>
                        </li>

                        {/* Search icon */}
                        <li className="nav-item">
                            <a className="nav-link" href="" data-search-open="">
                                <em className="icon-magnifier"></em>
                            </a>
                        </li>

                        {modules.length > 1 && modules.map(module => {
                            return (
                                <li key={module} className={'nav-item ' + (activeModule === module ? 'active' : '')}>
                                    <Button color="link" className="nav-link"
                                            onClick={() => this.handleModuleChange(module)}>
                                        {t('common:modules:' + module)}
                                    </Button>
                                </li>
                            )
                        })}
                    </ul>
                    {/* END Left navbar */}

                    {/* START Right Navbar */}
                    <ul className="navbar-nav flex-row">
                        {/* START stats button */}
                        {this.state.showSettingLinks && (
                            <li className="nav-item d-md-block">
                                <Link className="nav-link"
                                      to={{pathname: reverse(routes.appointments.statistics.overview)}}>
                                    <em className="icon-graph"></em>
                                </Link>
                            </li>
                        )}
                        {/* END stats button */}

                        {/* START Communication center menu */}
                        {this.state.showSettingLinks && (
                            <li className="nav-item d-md-block dropdown ">
                                <Link id="badge-communication" className="nav-link"
                                      to={{pathname: reverse(routes.communication.tasks.overview)}}>
                                    {badgeCount > 0 && (
                                        <>
                                            <span className="badge badge-danger">{badgeCount}</span>
                                            <UncontrolledTooltip placement="top" target="badge-communication">
                                                <table>
                                                    <tr>
                                                        <td className='text-left'>{t('tools.task:openTasks')}:</td>
                                                        <td width="25" className='text-right'>{pendingTasks}</td>
                                                    </tr>
                                                    {/*<tr>
                                                        <td className='text-left'>{t('profile.mailbox:unread')}:</td>
                                                        <td width="25" className='text-right'>{unreadMessages}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='text-left'>{t('tools.reminder:title')}:</td>
                                                        <td width="25" className='text-right'>{reminders}</td>
                                                    </tr>*/}
                                                </table>
                                            </UncontrolledTooltip>
                                        </>
                                    )}
                                    <em className="icon-bell"></em>
                                </Link>
                            </li>
                        )}
                        {/* END Communication center menu */}

                        {/* START Support button */}
                        <li className="nav-item d-md-block">
                            <Link to="#" className="nav-link" onClick={this.showSupportModal}>
                                <em className="icon-info"></em>
                            </Link>
                        </li>
                        {/* END Support button */}

                        {/* START Offsidebar button */}
                        {this.state.showSettingLinks && (
                            <li className="nav-item d-md-block">
                                <Link className="nav-link" to={{pathname: reverse(routes.profiles.overview)}}>
                                    <em className="icon-settings"></em>
                                </Link>
                            </li>
                        )}
                        {/* END Offsidebar menu */}

                        {/* START logout */}
                        <li className="nav-item d-md-block">
                            <Link to={{pathname: routes.auth.login}} title="Logout" className="nav-link">
                                <em className="icon-logout"></em>
                            </Link>
                        </li>
                        {/* END logout */}
                    </ul>
                    {/* END Right Navbar */}

                    {/* START Search form */}
                    <form className="navbar-form" role="search" onSubmit={this.onSearchSubmit}>
                        <div className="form-group">
                            <input className="form-control" type="text" placeholder={t('common:search.placeholder')}
                                   id="navbar-form-search-field"/>
                            <div className="fa fa-times navbar-form-close" data-search-dismiss=""></div>
                        </div>
                        <button className="d-none" type="submit">Submit</button>
                    </form>

                    <ModalSupport onRef={(ref) => this.modalSupport = ref}/>
                    {/* END Search form */}
                </nav>
                {/* END Top Navbar */}
            </header>
        );
    }

    handleModuleChange = (module) => {
        this.props.actions.changeSetting('activeModule', module);

        switch (module) {
            case 'agenda':
                this.props.history.push(reverse(routes.agenda.myagenda, {agenda: this.props.agenda.active.id}));
                break;
            case 'reservation':
                this.props.history.push(reverse(routes.reservation.reservations.overview));
                break;
            case 'shop':
                this.props.history.push(reverse(routes.giftvouchers.overview));
                break;
            default:
                this.props.history.push(reverse(routes.dashboard));
                break;
        }

        this.setState({showSettingLinks: this.doShowSettingsLink(module)});
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    notifications: state.notifications,
    group: state.group,
    agenda: state.agenda
});
const mapDispatchToProps = dispatch => ({actions: bindActionCreators(actions, dispatch)});

export default connect(mapStateToProps, mapDispatchToProps)(Header);