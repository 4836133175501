import { API } from "../services/api";

let _agenda = null;
const setAgenda = (agenda) => _agenda = agenda;

const get = (id) => {
    return API.get('client/agenda/agenda/get', { id: id })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.agenda;
            }

            return null;
        })
}

const getSetting = (key, def = null) => {
    if (!_agenda || !_agenda.settings) return def;
    return _agenda.settings[key] ? _agenda.settings[key] : def;
};

export const UtilAgenda = {
    get,
    setAgenda,
    getSetting
};