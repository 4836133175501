import { API } from '../api';

const getContact = (id) => {
    return API.get('client/contact/contact/get', { id: id })
        .then(
            response => {
                if (API.isSuccess(response)) {
                    return response.data.contact;
                }

                return null;
            }
        );

};

const getByGroup = (groupId) => {
    return API.get('client/contact/contact/get-by-group', { id: groupId })
        .then(
            response => {
                if (API.isSuccess(response)) {
                    return response.data.contacts;
                }

                return [];
            }
        );

};

const getByCallerId = (groupId, callerId) => {
    return API.get('client/contact/contact/get-by-caller-id', { group: groupId, callerId: callerId })
        .then(
            response => {
                if (API.isSuccess(response)) {
                    return response.data.callerResults;
                }

                return [];
            }
        );

};

const getUnconfirmedContacts = (groupId) => {
    return API.get('client/contact/contact/get-unconfirmed', { groupId: groupId })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.contacts;
            }

            throw response.data;
        });
};

const confirmDecline = (id, confirm, message) => {
    return API.post('client/contact/contact/confirm', { data: { id: id, confirm: confirm, message: message } })
        .then((response) => {
            if (API.isSuccess(response)) {
                return true;
            }

            throw response.data;
        });
};

const search = (data) => {
    return API.get('client/contact/contact/search', data)
        .then(
            response => {
                if (API.isSuccess(response)) {
                    return response.data.contacts;
                }

                return [];
            }
        )
};

const searchDt = (data) => API.get('client/contact/contact/search-dt', data)
    .then(response => response.data ? response.data : []);

const searchOverview = (data) => {
    return API.get('client/contact/contact/search-overview', data)
        .then(
            response => {
                if (API.isSuccess(response)) {
                    return response.data.contacts;
                }

                return [];
            }
        )
};

const resetStatus = (data) => {
    return API.post('client/contact/contact/reset-status', { data: data })
        .then(
            response => {
                if (API.isSuccess(response)) {
                    return response.data.contact;
                }

                return [];
            }
        )
};

const getFilters = (groupId) => {
    return API.get('client/contact/contact/get-filters', { groupId: groupId })
        .then(
            response => {
                if (API.isSuccess(response)) {
                    return response.data.filters;
                }

                return [];
            }
        )
};

const saveFilter = (id, data) => {
    return API.post('client/contact/contact/save-filter', { id: id, data: data })
        .then(
            response => {
                if (API.isSuccess(response)) {
                    return response.data.filter;
                }

                return false;
            }
        )
};

export const ApiContactService = {
    getContact,
    getByGroup,
    getByCallerId,
    getUnconfirmedContacts,
    confirmDecline,
    search,
    searchDt,
    searchOverview,
    resetStatus,
    getFilters,
    saveFilter
};