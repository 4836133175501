import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

/* loader component for Suspense*/
import PageLoader from './components/PageLoader';

import Base from './components/layout/Base';
import BasePage from './components/layout/BasePage';
import PrivateRoute from './routing/PrivateRoute';

import routes from './routing/routes';
import ErrorBoundary from './containers/Error/ErrorBoundary';
import componentLoader from './ComponentLoader';
import PermissionChecker from './components/PermissionChecker';
import ReservationOverviewCompact from './containers/Reservation/Reservation/ReservationOverviewCompact';

/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props} />;

const Dashboard = lazy(() => componentLoader(() => import('./containers/Dashboard/Dashboard')));

//agenda
const MyAgenda = lazy(() => componentLoader(() => import('./containers/Agenda/MyAgenda/MyAgenda')));
const GroupOverview = lazy(() => componentLoader(() => import('./containers/Agenda/Group/GroupOverview')));
const ResourceOverview = lazy(() => componentLoader(() => import('./containers/Agenda/Resource/ResourceOverview')));
//const HomeVisit = lazy(() => componentLoader(() => import('./containers/Agenda/HomeVisit/HomeVisit')));
const SearchFreeSlot = lazy(() => componentLoader(() => import('./containers/Agenda/SearchFreeSlot/SearchFreeSlot')));
const History = lazy(() => componentLoader(() => import('./containers/Agenda/History/History')));
const DayAppointment = lazy(() => componentLoader(() => import('./containers/Agenda/DayAppointment/DayAppointment')));
const Conflict = lazy(() => componentLoader(() => import('./containers/Agenda/Conflict/Conflict')));
const Appointement = lazy(() => componentLoader(() => import('./containers/Agenda/Appointment/Appointment')));
const Note = lazy(() => componentLoader(() => import('./containers/Agenda/Note/Note')));

//contacts
const Contact = lazy(() => componentLoader(() => import('./containers/Contact/Contact/Contact')));

// settings
const Profile = lazy(() => componentLoader(() => import('./containers/Settings/Profile/Profile')));
const AppointmentStatus = lazy(() => componentLoader(() => import('./containers/Settings/AppointmentStatus/AppointmentStatus')));
const Exception = lazy(() => componentLoader(() => import('./containers/Settings/Exception/Exception')));
const Holiday = lazy(() => componentLoader(() => import('./containers/Settings/Holiday/Holiday')));
const Blocking = lazy(() => componentLoader(() => import('./containers/Settings/Blocking/Blocking')));
const Category = lazy(() => componentLoader(() => import('./containers/Settings/Category/Category')));
const Subcategory = lazy(() => componentLoader(() => import('./containers/Settings/Subcategory/Subcategory')));
const CategoryForm = lazy(() => componentLoader(() => import('./containers/Settings/CategoryForm/CategoryForm')));
const Resource = lazy(() => componentLoader(() => import('./containers/Settings/Resource/Resource')));
const Tag = lazy(() => componentLoader(() => import('./containers/Settings/Tag/Tag')));
const Arrangement = lazy(() => componentLoader(() => import('./containers/Settings/Arrangement/Arrangement')));

// Shop
const Shop = lazy(() => componentLoader(() => import('./containers/Shop/Shop')));

// Vouchers
const GiftVoucher = lazy(() => componentLoader(() => import('./containers/GiftVoucher/GiftVoucher')));

// Transactions
const Transaction = lazy(() => componentLoader(() => import('./containers/Transaction/Transaction')));

// Tools
const Popup = lazy(() => componentLoader(() => import('./containers/Tools/Popup/Popup')));
const Task = lazy(() => componentLoader(() => import('./containers/Tools/Task/Task')));
//const Reminder = lazy(() => componentLoader(() => import('./containers/Tools/Reminder/Reminder')));

// Profile
//const MailBox = lazy(() => componentLoader(() => import('./containers/Profile/Mailbox/Mailbox')));
const AccountProfile = lazy(() => componentLoader(() => import('./containers/Profile/Profile/Profile')));
const AccountAuthentication = lazy(() => componentLoader(() => import('./containers/Profile/Profile/Authentication')));
const SettingsPersonal = lazy(() => componentLoader(() => import('./containers/Profile/Settings/Personal')));
const SettingsAgenda = lazy(() => componentLoader(() => import('./containers/Profile/Settings/Agenda')));
const SettingsLinks = lazy(() => componentLoader(() => import('./containers/Profile/Settings/Link/Link')));
const SettingsContact = lazy(() => componentLoader(() => import('./containers/Profile/Settings/Contact')));
const SettingGroupSort = lazy(() => componentLoader(() => import('./containers/Profile/Settings/GroupSort')));
const SettingGroup = lazy(() => componentLoader(() => import('./containers/Profile/Settings/Group')));
const SettingPairing = lazy(() => componentLoader(() => import('./containers/Profile/Settings/Pairing')));
const Subgroup = lazy(() => componentLoader(() => import('./containers/Settings/Subgroup/Subgroup')));

const Group = lazy(() => componentLoader(() => import('./containers/Settings/Group/Group')));
// Communication
const Campaign = lazy(() => componentLoader(() => import('./containers/Communication/Mailing/Campaign')));
const CampaignList = lazy(() => componentLoader(() => import('./containers/Communication/Mailing/List/MailingList')));
const Member = lazy(() => componentLoader(() => import('./containers/Communication/Mailing/Member/Member')));
const Mail = lazy(() => componentLoader(() => import('./containers/Communication/Mail/Mail')));
const Sms = lazy(() => componentLoader(() => import('./containers/Communication/Sms/Sms')));

// Reservations module
const ReservationReservations = lazy(() => componentLoader(() => import('./containers/Reservation/Reservation/Reservation')));

// Authentication
const Login = lazy(() => componentLoader(() => import('./containers/Pages/Login')));
const ForgotPassword = lazy(() => componentLoader(() => import('./containers/Pages/ForgotPassword')));
const ResetPassword = lazy(() => componentLoader(() => import('./containers/Pages/ResetPassword')));

const NotFound = lazy(() => componentLoader(() => import('./containers/Pages/NotFound')));
const NoAccess = lazy(() => componentLoader(() => import('./containers/Pages/NoAccess')));
const Forbidden = lazy(() => componentLoader(() => import('./containers/Pages/Forbidden')));
const ErrorPage = lazy(() => componentLoader(() => import('./containers/Pages/Error500')));

const Print = lazy(() => componentLoader(() => import('./containers/Pages/Print')));

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
    /* See full project for reference */
    routes.auth.login,
    routes.auth.forgotpassword,
    routes.auth.resetpassword,
    routes.auth.twofactor
    //routes.auth.resetpasswordcode
];

const Routes = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };

    // Animations supported
    //      'rag-fadeIn'
    //      'rag-fadeInRight'
    //      'rag-fadeInLeft'
    const animationName = ''; // 'rag-fadeIn'

    let isPage = false;
    listofPages.forEach(path => {
        if (location.pathname.indexOf(path) > -1) {
            isPage = true;
        }
    });

    if (
        // listofPages.indexOf(location.pathname) > -1 ||
        isPage ||
        location.pathname.includes('/print/') === true ||
        location.pathname.includes('/e/') === true ||
        location.pathname.includes('/compact/') === true
    ) {
        return (
            // Page Layout component wrapper
            <BasePage>
                <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        <Route path={routes.e.al} component={waitFor(Login)} />

                        {/* See full project for reference */}
                        <Route path={routes.auth.resetpasswordcode} component={waitFor(ResetPassword)} />
                        <Route path={routes.auth.resetpassword} component={waitFor(ResetPassword)} />
                        <Route path={routes.auth.forgotpassword} component={waitFor(ForgotPassword)} />
                        <Route path={routes.auth.login} component={waitFor(Login)} />

                        <PrivateRoute path={routes.appointments.print} component={waitFor(Print)} />
                        <PrivateRoute path={routes.agenda.printWeek} component={waitFor(Print)} />
                        <PrivateRoute path={routes.agenda.printDay} component={waitFor(Print)} />
                        <PrivateRoute path={routes.agenda.groupPrint} component={waitFor(Print)} />
                        <PrivateRoute path={routes.agenda.resourcePrint} component={waitFor(Print)} />
                        <PrivateRoute path={routes.homevisits.printDay} component={waitFor(Print)} />

                        <PrivateRoute path={routes.reservation.reservations.compact} component={waitFor(ReservationOverviewCompact)} />
                    </Switch>
                </Suspense>
            </BasePage>
        )
    } else {
        return (
            // Layout component wrapper
            <Base>
                <ErrorBoundary>
                    <TransitionGroup>
                        <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                            <Suspense fallback={<PageLoader />}>
                                <PermissionChecker location={location}>
                                    <Switch location={location}>
                                        <PrivateRoute exact path={routes.home} component={waitFor(Dashboard)} />
                                        <PrivateRoute exact path={routes.dashboard} component={waitFor(Dashboard)} />

                                        <PrivateRoute path={routes.agenda.myagenda} component={waitFor(MyAgenda)} />
                                        <PrivateRoute path={routes.agenda.appointmentCopy} exact component={waitFor(MyAgenda)} />
                                        <PrivateRoute path={routes.agenda.appointmentMove} exact component={waitFor(MyAgenda)} />
                                        <PrivateRoute path={routes.notes.overview} component={waitFor(Note)} />

                                        <PrivateRoute path={routes.agenda.appointmentMoveAgenda} exact component={waitFor(GroupOverview)} />
                                        <PrivateRoute path={routes.agenda.group} component={waitFor(GroupOverview)} />
                                        <PrivateRoute path={routes.agenda.subgroup} component={waitFor(GroupOverview)} />
                                        <PrivateRoute path={routes.agenda.resources} component={waitFor(ResourceOverview)} />
                                        <PrivateRoute path={routes.dayappointments.overview} component={waitFor(DayAppointment)} />

                                        {/*<PrivateRoute path={routes.homevisits.overview} component={waitFor(HomeVisit)} />*/}

                                        <PrivateRoute path={routes.agenda.searchfreeslot} component={waitFor(SearchFreeSlot)} />
                                        <PrivateRoute path={routes.agenda.history} component={waitFor(History)} />
                                        <PrivateRoute path={routes.agenda.conflicts} component={waitFor(Conflict)} />

                                        <PrivateRoute path={routes.appointments.overview} component={waitFor(Appointement)} />
                                        <PrivateRoute path={routes.lastminutes.overview} component={waitFor(Appointement)} />

                                        <PrivateRoute path={routes.contacts.overview} component={waitFor(Contact)} />

                                        <PrivateRoute path={routes.forms.overview} component={waitFor(CategoryForm)} />
                                        <PrivateRoute path={routes.categories.overview} component={waitFor(Category)} />
                                        <PrivateRoute path={routes.profiles.overview} component={waitFor(Profile)} />
                                        <PrivateRoute path={routes.subcategories.overview} component={waitFor(Subcategory)} />
                                        <PrivateRoute path={routes.appointmentstatuses.overview} component={waitFor(AppointmentStatus)} />
                                        <PrivateRoute path={routes.holidays.overview} component={waitFor(Holiday)} />
                                        <PrivateRoute path={routes.exceptions.overview} component={waitFor(Exception)} />
                                        <PrivateRoute path={routes.blockings.overview} component={waitFor(Blocking)} />
                                        <PrivateRoute path={routes.resources.overview} component={waitFor(Resource)} />
                                        <PrivateRoute path={routes.tags.overview} component={waitFor(Tag)} />
                                        <PrivateRoute path={routes.settings.arrangements.overview} component={waitFor(Arrangement)} />

                                        <PrivateRoute path={routes.shop.overview} component={waitFor(Shop)} />

                                        <PrivateRoute path={routes.giftvouchers.overview} component={waitFor(GiftVoucher)} />

                                        <PrivateRoute path={routes.transactions.overview} component={waitFor(Transaction)} />

                                        <PrivateRoute path={routes.communication.tasks.overview} component={waitFor(Task)} />
                                        {/*<PrivateRoute path={routes.communication.reminders.overview} component={waitFor(Reminder)} />*/}
                                        <PrivateRoute path={routes.communication.popups.overview} component={waitFor(Popup)} />

                                        {/*<PrivateRoute path={routes.communication.mailbox.inbox} component={waitFor(MailBox)} />*/}

                                        <PrivateRoute path={routes.settings.account.profile} component={waitFor(AccountProfile)} />
                                        <PrivateRoute path={routes.settings.account.authentication} component={waitFor(AccountAuthentication)} />
                                        <PrivateRoute path={routes.settings.personal} component={waitFor(SettingsPersonal)} />
                                        <PrivateRoute path={routes.settings.agenda} component={waitFor(SettingsAgenda)} />
                                        <PrivateRoute path={routes.settings.links.overview} component={waitFor(SettingsLinks)} />
                                        <PrivateRoute path={routes.settings.group.contact} component={waitFor(SettingsContact)} />
                                        <PrivateRoute path={routes.settings.group.sort} component={waitFor(SettingGroupSort)} />
                                        <PrivateRoute path={routes.settings.group.agenda} component={waitFor(SettingGroup)} />
                                        <PrivateRoute path={routes.settings.pairing.overview} component={waitFor(SettingPairing)} />
                                        <PrivateRoute path={routes.subgroups.overview} component={waitFor(Subgroup)} />

                                        <PrivateRoute path={routes.settings.group.profile} component={waitFor(Group)} />

                                        <PrivateRoute path={routes.communication.mailing.list.member.overview} component={waitFor(Member)} />
                                        <PrivateRoute path={routes.communication.mailing.list.overview} component={waitFor(CampaignList)} />
                                        <PrivateRoute path={routes.communication.mailing.campaign.overview} component={waitFor(Campaign)} />
                                        <PrivateRoute path={routes.communication.mail.compose} component={waitFor(Mail)} />
                                        <PrivateRoute path={routes.communication.sms.compose} component={waitFor(Sms)} />

                                        <PrivateRoute path={routes.reservation.reservations.overview} component={waitFor(ReservationReservations)} />

                                        <PrivateRoute path={routes.error} component={waitFor(ErrorPage)} />
                                        <PrivateRoute path={routes.noaccess} component={waitFor(NoAccess)} />
                                        <PrivateRoute path={routes.forbidden} component={waitFor(Forbidden)} />
                                        <PrivateRoute path={routes.notfound} component={waitFor(NotFound)} />

                                        <Redirect to={routes.notfound} />
                                    </Switch>
                                </PermissionChecker>
                            </Suspense>
                        </CSSTransition>
                    </TransitionGroup>
                </ErrorBoundary>
            </Base>
        )
    }
};

export default withRouter(Routes);
