import { toast } from "react-toastify";
import { t } from 'i18next';
import { LocalStorageService } from "../services/local-storage.service";
import swal from "sweetalert";

const toastError = (message) => toastNotify(message ? message : t('common:error.general'), toast.TYPE.ERROR);
const toastInfo = (message) => toastNotify(message, toast.TYPE.INFO);
const toastSuccess = (message) => toastNotify(message ? message : t('common:saveSuccessMessage'), toast.TYPE.SUCCESS);
const toastWarning = (message) => toastNotify(message, toast.TYPE.WARNING);
const toastNotify = (message, type = 'success') => toast(message, { type: type, position: toast.POSITION.TOP_RIGHT });

const error = (message) => notify(message ? message : t('common:errorMessage'), 'error');
const success = (message) => notify(message ? message : t('common:saveSuccessMessage'), 'success');
const warning = (message) => notify(message, 'warning');
const notify = (message, status) => LocalStorageService.store('FlashMessage', { status: status, message: message });

const swalError = (title, message) => swalNotify(title, message, swal.TYPE.ERROR);
const swalSuccess = (title, message) => swalNotify(title, message, swal.TYPE.SUCCESS);
const swalWarning = (title, message) => swalNotify(title, message, swal.TYPE.WARNING);
const swalNotify = (title, message, type) => swal(title, message, type ? type : swal.TYPE.INFO);

export const UtilNotification = {
    toastError,
    toastInfo,
    toastSuccess,
    toastWarning,

    error,
    success,
    warning,

    swalError,
    swalSuccess,
    swalWarning
};