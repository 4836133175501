import React from 'react';
import TableRowLoader from './TableRowLoader';
import TableRowNoResult from './TableRowNoResult';

class TableBody extends React.Component {
    render() {
        const { loading, cols, children, id, className, message } = this.props;

        return (
            <tbody id={id ? id : 'table-body'} className={className ? className : ''}>
                <TableRowLoader loading={loading} colSpan={cols}></TableRowLoader>
                <TableRowNoResult items={children} loading={loading} colSpan={cols} message={message}></TableRowNoResult>
                {children}
            </tbody>
        );
    }
}

export default TableBody;