import React from 'react';
import PieSocket from 'piesocket-js';
import { connect } from 'react-redux';
import * as actions from '../store/actions';
import { bindActionCreators } from 'redux';
import { ApiContactService } from '../services/api/index';

class PhoneSocket extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;
        this._isConnected = false;

        this.state = {
            channelId: props.group.settings && props.group.settings.phoneSocketChannelId ? props.group.settings.phoneSocketChannelId : null,
            connectedPhoneNumber: null,
            socket: null
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.connect();
    }

    componentWillUnmount() {
        this._isMounted = false;
        
        if (this.state.socket && this._isConnected) {
            this.state.socket.unsubscribe(this.state.channelId);
        }
    }

    render() {
        return null;
    }

    connect = () => {
        if (!this.state.channelId) return;

        console.log('init socket connection');

        let clusterId = process.env.REACT_APP_EGEO_PHONE_SOCKET_CHANNEL_ID;
        let apiKey = process.env.REACT_APP_EGEO_PHONE_SOCKET_API_KEY;
        let channelId = this.state.channelId;

        // wss://CLUSTER_ID.piesocket.com/v3/CHANNEL_ID?api_key=API_KEY
        let socket = new PieSocket({
            clusterId: clusterId,
            apiKey: apiKey,
            notifySelf: 1
        });

        let channel = socket.subscribe(channelId);
        let me = this;

        channel.on('open', () => {
            console.log("Socket connected!");
            this.connected(true);
        });

        channel.on('close', () => {
            console.log("Socket connection closed!");
            this.connected(false);
        });

        channel.on('message', (event) => {
            console.log("Socket message received ...");

            let data = JSON.parse(event.data);

            if (data.error) {
                console.log('ERROR: ' + data.error);
                //swal({ title: 'Phone socket', text: data.error, icon: 'error' });
            } else {
                me.onNumberReceive(data);
            }
        });

        channel.on('error', (event) => {
            console.log('error', event);
        });

        if (this._isMounted) {
            this.setState({ socket: socket });
        }
    }

    onNumberReceive = ({ extension, number }) => {
        // {extension: '600', number: '0472470716'}
        console.log(extension + ' --> ' + number);

        let { phoneSocket } = this.props.settings;

        if (phoneSocket && phoneSocket.selectedDevice && phoneSocket.selectedDevice === extension.toString()) {
            number = number !== 'hangup' ? number : null;

            if (number) {
                // lookup
                ApiContactService.getByCallerId(this.props.group.id, number)
                    .then(results => {
                        this.props.actions.setContact(null);

                        phoneSocket.connectedPhoneNumber = number;
                        this.props.actions.changeSetting('phoneSocket', phoneSocket);

                        this.props.actions.changeSetting('caller', {
                            id: number,
                            results: results && results.length > 0 ? results : []
                        });
                    })
                    .catch(error => console.log(error))
            } else {
                // hangup
                phoneSocket.connectedPhoneNumber = number;
                this.props.actions.changeSetting('phoneSocket', phoneSocket);
                this.reset();
            }
        }
    }

    connected = (connected) => {
        this._isConnected = connected;

        let { phoneSocket } = this.props.settings;
        if (!phoneSocket) {
            phoneSocket = {
                connected: false,
                selectedDevice: null,
                connectedPhoneNumber: null
            }
        }

        phoneSocket.connected = connected;
        this.props.actions.changeSetting('phoneSocket', phoneSocket);
    }

    reset = () => {
        this.props.actions.setContact(null);
        this.props.actions.changeSetting('caller', {
            id: null,
            results: []
        });
    }
}

const mapStateToProps = state => ({ group: state.group ? state.group : null, settings: state.settings ? state.settings : {} });
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(PhoneSocket);