import routes from '../routing/routes';

const MenuOffsidebar = [
  {
        name: 'Instellingen',
        icon: 'icon-settings',
        translate: 'sidebar:settings:main',
        submenu: [{
            name: 'Profielen',
            translate: 'sidebar:settings:profiles',
            path: routes.profiles.overview
        },
        {
            name: 'Afspraak statussen',
            translate: 'sidebar:settings:appointmentstatuses',
            path: routes.appointmentstatuses.overview
        },
        {
            name: 'Uitzonderingen',
            translate: 'sidebar:settings:exceptions',
            path: routes.exceptions.overview
        },
        {
            name: 'Blokeer tijdstip',
            translate: 'sidebar:settings:blockings',
            path: routes.blockings.overview
        },
        {
            name: 'Vakantieperiodes',
            translate: 'sidebar:settings:holidays',
            path: routes.holidays.overview
        },
        {
            name: 'Categorieën',
            translate: 'sidebar:settings:categories',
            path: routes.categories.overview
        },
        {
            name: 'Type afspraken',
            translate: 'sidebar:settings:subcategories',
            path: routes.subcategories.overview
        },
        {
            name: 'Formulieren',
            translate: 'sidebar:settings:forms',
            path: routes.forms.overview
        }]
    },
    {
        name: 'Persoonlijke instellingen',
        translate: 'sidebar:personalsettings:main',
        icon: 'icon-paper-clip',
        submenu: [
            {
                name: 'Mijn voorkeuren',
                translate: 'sidebar:personalsettings:mypreferences',
                path: routes.settings.personal
            },
            {
                name: 'Agenda voorkeuren',
                translate: 'sidebar:personalsettings:agendapreferences',
                path: routes.settings.agenda
            }]
    }
];

export default MenuOffsidebar;