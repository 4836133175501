const format = (number, precision = 2) => {
    return parseFloat(number).toFixed(precision);
};

const formatCurrency = (number, precision = 2, currency = 'EUR') => {
    let sign = '';
    switch (currency) {
        case 'EUR':
        default:
            sign = '€';
            break;
    }

    return sign + ' ' + format(number, precision);
};

export const UtilPrice = {
    format,
    formatCurrency
};