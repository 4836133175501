import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

// App Routes
import Routes from './Routes';

// Vendor dependencies
import "./Vendor";

// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss'
import { withNamespaces } from 'react-i18next';

import configureStore from './store/store';
import { LastLocationProvider } from 'react-router-last-location';
import { ToastContainer } from 'react-toastify';
import RouteChangeTracker from './RouteChangeTracker';
// import GA4React from "ga-4-react";
import ReactTooltip from 'react-tooltip';
import { UtilCalendar } from './utils';

const store = configureStore();
//const ga4react = new GA4React('G-41W2XBXVEW');

class App extends Component {
  /*constructor(props) {
    super(props);
    ga4react.initialize();
  }*/

  componentDidMount() {
    const { client } = store.getState();

    if (!client) {
      return;
    }

    let nativeLanguage = 'nl';
    if (client.logged_in) {
      nativeLanguage = client.logged_in.nativeLanguage;
    }

    if (this.props.i18n.language !== nativeLanguage) {
      this.props.i18n.changeLanguage(nativeLanguage);
    }

    document.addEventListener('click', this.handleBodyClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleBodyClick);
  }

  handleBodyClick = () => UtilCalendar.resetTooltip();

  render() {

    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    /* global PUBLIC_URL */
    const basename = process.env.NODE_ENV === 'development' ? '/' : (PUBLIC_URL || '/');

    return (
      <BrowserRouter basename={basename}>
        <LastLocationProvider>
          <RouteChangeTracker />
          <ToastContainer pauseOnFocusLoss={false} autoClose={2000} />
          <ReactTooltip className="z-index-2" />
          <Routes />
        </LastLocationProvider>
      </BrowserRouter>
    );
  }
}

export default withNamespaces()(App);