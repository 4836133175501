import { API } from '../api';

const getById = (id) => {
    return API.get('client/communication/mailing/campaign/get', { id: id })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.mailing;
            }

            return {};
        });
};

export const ApiCommunicationService = {
    getById
};