import { Button } from 'reactstrap';
import React from 'react';
import UncontrolledTooltip from '../reactstrap/UncontrolledTooltip';

const ButtonIcon = props => (
    <>
        <Button size={props.size} color={props.color} className={'btn-labeled ' + props.className} id={'buttonIcon' + props.id} onClick={props.onClick}>
            <span className='btn-label'><i className={'fa fas fa-' + props.icon} aria-hidden></i></span>
            {props.children ? props.children : null}
        </Button>
        {props.tooltip && (
            <UncontrolledTooltip placement="top" target={'buttonIcon' + props.id}>
                {props.tooltip}
            </UncontrolledTooltip>
        )}
    </>
)

ButtonIcon.defaultProps = {
    color: 'default',
    size: 'sm'
}

export default ButtonIcon;