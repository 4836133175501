import React from 'react';
import PropTypes from 'prop-types';
import FlashMessage from '../FlashMessage';
import ModalGroupSelect from '../modals/ModalGroupSelect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions';
import moment from 'moment';
import { reverse } from 'named-urls';
import routes from '../../routing/routes';
import { withNamespaces } from 'react-i18next';
import Popup from '../Popup';
import PhoneSocket from '../PhoneSocket';
/**
 * Wrapper element for template content
 */
class ContentWrapper extends React.Component {
    constructor(props) {
        super(props);

        let { lastActivity } = props.settings;
        if (lastActivity) {
            lastActivity = moment(lastActivity);
            if (lastActivity.diff(moment(), 'hours', true) > 12) {
                let authLink = reverse(routes.auth.login);
                props.history ? props.history.push(authLink) : window.location = authLink;
            }
        }
        props.actions.changeSetting('lastActivity', moment().format('YYYY-MM-DD HH:mm:ss'));
        props.actions.setPageSettings({ title: null, subtitle: null });

        if (props.loggedInclient && props.loggedInclient.nativeLanguage) {
            if (props.i18n.language !== props.loggedInclient.nativeLanguage) {
                props.i18n.changeLanguage(props.loggedInclient.nativeLanguage);
            }
        }
    }

    componentDidMount() {
        this.props.actions.changeSetting('isCollapsedText', this.props.settings.wasCollapsedBeforeSettings);
    }

    render() {
        return (
            <div className={'content-wrapper ' + this.props.className} >
                <ModalGroupSelect {...this.props} />
                <FlashMessage />
                <Popup />
                <PhoneSocket />

                {this.props.unwrap ?
                    (<div className="unwrap">{this.props.children}</div>)
                    :
                    (this.props.children)
                }
            </div >
        )
    }
}

ContentWrapper.propTypes = {
    /** add element with 'unwrap' class to expand content area */
    unwrap: PropTypes.bool
}
ContentWrapper.defaultProps = {
    unwrap: false
}

const mapStateToProps = state => ({ client: state.client ? state.client.active : null, loggedInclient: state.client ? state.client.logged_in : null, settings: state.settings });
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(ContentWrapper));
