import React from 'react';
import { Button } from 'reactstrap';
import { t } from 'i18next';
import UncontrolledTooltip from '../reactstrap/UncontrolledTooltip';

const ButtonActionRemove = props => (
    <React.Fragment>
        <Button color="danger" size="sm" id={'buttonRemove' + props.id} onClick={() => { props.modalRemove.fire({ id: props.id }); }}>
            <i className="fas fa-trash" aria-hidden></i>
        </Button>
        <UncontrolledTooltip placement="top" target={'buttonRemove' + props.id}>
            {t('common:action-remove')}
        </UncontrolledTooltip>
    </React.Fragment>
)

export default ButtonActionRemove;