import { AGENDA_SET, AGENDA_UPDATE, AGENDA_UPDATE_SETTINGS, AGENDA_REMOVE, AGENDA_ACCESS_KEY_SET, AGENDA_ACCESS_KEY_REMOVE, AGENDA_LOGGED_IN, AGENDA_LOGGED_OUT } from './agenda.actions';
import { CLIENT_LOGGED_OUT } from '../client/client.actions';

const agendaReducer = (state = null, action) => {
    switch (action.type) {
        case AGENDA_LOGGED_IN:
            return action.value;
        case AGENDA_LOGGED_OUT:
        case CLIENT_LOGGED_OUT:
            return null;
        case AGENDA_SET:
        case AGENDA_UPDATE:
            return {
                ...state,
                [action.name]: action.value
            }
        case AGENDA_REMOVE:
            return {
                ...state,
                [action.name]: undefined
            }
        case AGENDA_UPDATE_SETTINGS:
            return {
                ...state,
                [action.name]: {
                    ...state[action.name],
                    settings: action.value
                }
            }
        case AGENDA_ACCESS_KEY_SET:
            return {
                ...state,
                access_key: action.value
            }
        case AGENDA_ACCESS_KEY_REMOVE:
            return {
                ...state,
                access_key: undefined
            }
        default:
            return state;
    }
}

export default agendaReducer;