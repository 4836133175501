import React from 'react';

// CSS Loaders
import 'loaders.css/loaders.css';
import 'spinkit/css/spinkit.css';

const Spinner = props => (
    <div className={'spinner-wrapper ' + (!props.show ? 'd-none' : '')}>
        <div className="ball-clip-rotate-multiple">
            <div></div>
            <div></div>
        </div>
    </div>
)

export default Spinner;
