import React from 'react';
import { Badge } from 'reactstrap';
import UncontrolledTooltip from '../reactstrap/UncontrolledTooltip';

const BadgeTooltip = props => (
    <React.Fragment>
        <Badge pill={props.pill ? props.pill : false} color={props.color} style={props.style} id={'badgeTooltip' + props.id} className={props.className}>
            {props.children}
        </Badge>
        <UncontrolledTooltip placement="top" target={'badgeTooltip' + props.id}>
            {props.tooltip ? props.tooltip : props.children}
        </UncontrolledTooltip>
    </React.Fragment>
)

export default BadgeTooltip;