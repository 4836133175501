import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

const ButtonLink = props => (
    <Button color={props.color ? props.color : 'default'}
        tag={props.tag ? props.tag : Link} to={props.to}
        className={'btn-labeled ' + props.className}
        disabled={props.disabled ? true : false}
        size={props.size ? props.size : 'md'}
    >
        <span className="btn-label">
            <i className={'fa fa-' + props.icon} aria-hidden></i>
        </span>
        {props.children}
    </Button>
)
export default ButtonLink;