import { API } from '../api';

const microsoftMe = (agendaId) => {
    return API.get('client/pairing/msgraph/me', { agendaId: agendaId })
        .then(response => { return API.isSuccess(response) ? response.data.me : null });
};

const microsoftGraphConnect = (agendaId) => {
    return API.get('client/pairing/msgraph/authorize', { agendaId: agendaId })
        .then(
            response => {
                if (API.isSuccess(response)) {
                    return response.data.redirect;
                }

                throw new Error('Something went wrong');
            });
};
const microsoftGraphDisconnect = (agendaId) => {
    return API.post('client/pairing/msgraph/disconnect', { data: { agendaId: agendaId } })
        .then(
            response => {
                if (API.isSuccess(response)) {
                    return response.data.agenda;
                }

                throw new Error('Something went wrong');
            });
};

export const ApiPairingService = {
    microsoftMe,
    microsoftGraphConnect,
    microsoftGraphDisconnect
};