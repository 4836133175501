const hexToRGB = (hex) => {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
};

const contrastPercentage = (hex) => {
    let rgb = hexToRGB(hex);
    if (rgb) {
        let r = rgb.r,
            g = rgb.g,
            b = rgb.b;
        let luminance = (r * 299 + g * 587 + b * 114) / 1000;
        return luminance / 255;
    }
    return null;
};

const contrastBlackOrWhite = (hex) => {
    return (contrastPercentage(hex) >= 0.5) ? '#000000' : '#FFFFFF';
}

const generateStyleAttributeColors = (hex) => {
    return {
        backgroundColor: hex,
        color: contrastBlackOrWhite(hex)
    };
}

const lightenDarkenColor = (col, amt) => {
    let usePound = false;
  
    if (col[0] === '#') {
        col = col.slice(1);
        usePound = true;
    }
 
    let num = parseInt(col,16);
    let r = (num >> 16) + amt;

    if (r > 255) {
        r = 255;
    } else if (r < 0) {
        r = 0;
    }
 
    let b = ((num >> 8) & 0x00FF) + amt;
 
    if (b > 255) b = 255;
    else if  (b < 0) b = 0;
 
    let g = (num & 0x0000FF) + amt;
 
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
 
    return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
  
}

export const UtilColor = {
    hexToRGB,
    contrastPercentage,
    contrastBlackOrWhite,
    generateStyleAttributeColors,
    lightenDarkenColor
};