import { API } from '../../services/api';
import { GROUP_UPDATE } from "./group.actions";

/*
   Hook into setting changes in order to change layout.
*/
const group = store => next => action => {
    let result = next(action);

    if (action.type === GROUP_UPDATE) {
        API.setGroup(action.value.id);
    }

    return result
}

export default group;