import React from 'react';
import { Button } from 'reactstrap';
import UncontrolledTooltip from '../reactstrap/UncontrolledTooltip';

const ButtonActionCustom = props => (
    <React.Fragment>
        <Button color={props.color ? props.color : 'info'} size={props.size ? props.size : 'sm'}
            id={'buttonCustom' + props.id}
            className={props.className}
            disabled={!!props.disabled}
            onClick={props.onClick}
        >
            <i className={'fas fa-' + props.icon} aria-hidden></i>
        </Button>
        {props.tooltip && (
            <UncontrolledTooltip placement="top" target={'buttonCustom' + props.id}>
                {props.tooltip}
            </UncontrolledTooltip>
        )}
    </React.Fragment>
)

export default ButtonActionCustom;