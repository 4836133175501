import React from 'react';
import { t } from 'i18next';
import ContentWrapper from '../../components/layout/ContentWrapper';

const NoAccess = props => (
    <ContentWrapper>
        <div className="abs-center wd-xl">
            <div className="text-center mb-4">
                <div className="text-lg mb-3">401</div>
                <p className="lead m-0">{t('pages:no-access:title')}</p>
                <p>{t('pages:no-access:text')}</p>
            </div>
        </div>
    </ContentWrapper>
)

export default NoAccess;