import React from 'react';
import { t } from 'i18next';

class TableRowNoResult extends React.Component {
    render() {
        const { items, colSpan, loading } = this.props;

        let length = 0;
        if (items instanceof Object) {
            length = Object.keys(items).length;
        } else if (items instanceof Array) {
            length = items.length;
        }

        if (length > 0 || loading) {
            return null;
        }

        return (
            <tr>
                <td colSpan={colSpan} className="text-center">
                    {this.props.children ? this.props.children : (this.props.message ? this.props.message : t('common:no.result'))}
                </td>
            </tr>
        );
    }
}

export default TableRowNoResult;
