import { toast } from "react-toastify";

const generateRandomId = (length = 32, upperCaseAllowed = false) => {
    let characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    if (upperCaseAllowed) {
        characters += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }

    let charactersLength = characters.length;
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

const notify = (type, message, position = 'top-right') => {
    toast(message, { type: type, position: position });
};

export const UtilSystem = {
    generateRandomId,
    notify
};