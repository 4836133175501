import { API } from "../api";

const getById = (id, view = null) => {
  return API.get("client/appointment/appointment/get-appointment", {
    id: id,
    view: view,
  }).then((response) => {
    if (API.isSuccess(response)) {
      return response.data.appointment;
    }

    throw response.data;
  });
};

const getByRepeatId = (id) => {
  return API.get("client/appointment/appointment/get-repeated", {
    repeatId: id,
  }).then((response) => {
    if (API.isSuccess(response)) {
      return response.data.appointments;
    }

    throw response.data;
  });
};

const getByContact = (
  contactId,
  groupId,
  dateType = "upoming",
  repeated = false
) => {
  return API.get("client/appointment/appointment/get-by-contact", {
    contactId: contactId,
    dateType: dateType,
    repeated: repeated,
    groupId: groupId,
  }).then((response) => {
    if (API.isSuccess(response)) {
      return response.data.appointments;
    }

    return [];
  });
};

const getByGroup = (
  groupId,
  page = 1,
  perPage = 50,
  searchStr = "",
  dateType = "",
  dateFrom = "",
  dateTill = ""
) => {
  return API.get("client/appointment/appointment/get-by-group", {
    id: groupId,
    p: page,
    pp: perPage,
    searchStr: searchStr,
    dateType: dateType,
    dateFrom: dateFrom,
    dateTill: dateTill,
  }).then((response) => {
    if (API.isSuccess(response)) {
      return response.data.appointments;
    }

    return [];
  });
};

const quickUpdate = (id, data) => {
  return API.post("client/appointment/appointment/quick-update", {
    id: id,
    data: data,
  }).then((response) => {
    if (API.isSuccess(response)) {
      return response.data;
    }

    throw response.data[Object.keys(response.data)[0]];
  });
};

const moveAppointment = (appointmentId, start, copy = false, agenda = null) => {
  let requestData = {
    id: appointmentId,
    data: { start: start, copy: copy },
  };

  if (agenda) {
    requestData["data"]["agenda"] = agenda;
  }

  return API.post("client/appointment/appointment/move", requestData).then(
    (response) => {
      if (API.isSuccess(response)) {
        return response.data;
      }

      throw response.data[Object.keys(response.data)[0]];
    }
  );
};

const search = (searchStr, type, groupId) => {
  return API.get("client/appointment/appointment/search", {
    searchStr: searchStr,
    dateType: type,
    groupId: groupId,
  }).then((response) => {
    if (API.isSuccess(response)) {
      return response.data.appointments;
    }

    return [];
  });
};

const removeContactUpcomingAppointments = (contactId) => {
  return API.remove("client/appointment/appointment/remove-contact-upcoming", {
    contactId: contactId,
  }).then((response) => {
    if (API.isSuccess(response)) {
      return true;
    }

    throw response.data;
  });
};

const getStatistics = (groupId) => {
  return API.post("client/appointment/appointment/get-statistics", {
    data: { groupId: groupId },
  }).then((response) => {
    if (API.isSuccess(response)) {
      return response.data;
    }

    throw response.data[Object.keys(response.data)[0]];
  });
};

const getLogsByAppointment = (appointmentId) => {
  return API.get("client/appointment/appointment/get-logs", {
    id: appointmentId,
  }).then((response) => {
    if (API.isSuccess(response)) {
      return response.data;
    }

    return false;
  });
};

const saveRepeated = (data) => {
  return API.post(
    "client/appointment/appointment/create-appointment-repeated",
    { data: data }
  ).then((response) => {
    if (API.isSuccess(response)) {
      return response.data;
    }

    return false;
  });
};

export const ApiAppointmentService = {
  getById,
  getByRepeatId,
  getByContact,
  getByGroup,
  quickUpdate,
  moveAppointment,
  search,
  removeContactUpcomingAppointments,
  getStatistics,
  getLogsByAppointment,
  saveRepeated,
};
