import { API } from '../api';

const get = (id) => API.get('client/arrangement/arrangement/lastminute/lastminute/get', { id: id })
    .then(response => API.isSuccess(response) ? response.data.lastminute : null);

const getByGroup = (groupId) => API.get('client/arrangement/lastminute/lastminute/get-by-group', { id: groupId })
    .then(response => API.isSuccess(response) ? response.data.lastminutes : []);

const create = (data) => API.post('client/arrangement/lastminute/lastminute/create-quick', { data: data })
    .then(response => {
        if (API.isSuccess(response)) {
            return response;
        }
        throw response.data[Object.keys(response.data)[0]];
    });

const copy = (data) => API.post('client/arrangement/lastminute/lastminute/copy', { id: data.id, data: data }).then(response => API.isSuccess(response));

const move = (id, start, copy = false, agenda = null) => {
    let requestData = {
        id: id, data: { start: start, copy: copy }
    };

    if (agenda) {
        requestData['data']['agenda'] = agenda;
    }

    return API.post('client/arrangement/lastminute/lastminute/move', requestData)
        .then((response) => {
            if (API.isSuccess(response)) {
                return response.data;
            }

            throw response.data[Object.keys(response.data)[0]];
        })
};

const getTemplate = (id) => API.get('client/arrangement/lastminute/template/get', { id: id })
    .then(response => API.isSuccess(response) ? response.data.template : null);

const getTemplatesByGroup = (groupId) => API.get('client/arrangement/lastminute/template/get-by-group', { id: groupId })
    .then(response => API.isSuccess(response) ? response.data.templates : []);

const updateTemplateStaffels = (id, data) => API.post('client/arrangement/lastminute/template/staffels', { template: id, data: data })
    .then(response => API.isSuccess(response));

export const ApiArrangementLastMinuteService = {
    get,
    getByGroup,

    create,
    copy,
    move,

    getTemplate,
    getTemplatesByGroup,
    updateTemplateStaffels
};