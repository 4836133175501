import { API } from '../api';

const getByDate = (date, groupId, locationId, groupBy = null) => {
    return API.get('client/reservation/reservation/get-by-date', { date: date, groupId: groupId, locationId: locationId, groupBy: groupBy })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data
            }

            return [];
        })
}

const getById = (id) => {
    return API.get('client/reservation/reservation/get', { id: id })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data
            }

            return null;
        })
}

const quickUpdate = (id, data) => {
    return API.post('client/reservation/reservation/quick-update', { id: id, data: data })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data
            }

            return false;
        })
}

export const ApiReservationService = {
    getByDate,
    getById,
    quickUpdate
};