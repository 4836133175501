import { combineReducers } from 'redux';

import agendaReducer from './agenda/agenda.reducer';
import clientReducer from './client/client.reducer';
import contactReducer from './contact/contact.reducer';
import groupReducer from './group/group.reducer';
import locationReducer from './location/location.reducer';
import notificationsReducer from './notifications/notifications.reducer';
import pageReducer from './page/page.reducer';
import schemaDataReducer from './schema-data/schema-data.reducer';
import settingsReducer from './settings/settings.reducer';

export default combineReducers({
    agenda: agendaReducer,
    client: clientReducer,
    contact: contactReducer,
    group: groupReducer,
    location: locationReducer,
    notifications: notificationsReducer,
    page: pageReducer,
    schemaData: schemaDataReducer,
    settings: settingsReducer
});